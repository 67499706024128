import React, { useState } from "react"
import {
	IconButton,
	Menu,
	MenuItem,
	ListItemText,
	Typography,
	Grid,
	Tooltip,
	SvgIconProps,
	Divider as MuiDivider,
	ListItemIcon
} from "@material-ui/core"

import {
	TextEnhancementIcon,
	GeneralTextImprovementIcon,
	ConfidentToneIcon,
	FriendlyToneIcon,
	ProfessionalToneIcon,
	DirectToneIcon,
	EmpatheticToneIcon
} from "@/assets/icons"

import { Divider, SvgIcon, UpsellDialog } from "@/components"

import TextEnhancementDialog, { TextEnhancementDialogProps } from "@/components/Skynet/TextEnhancementDialog"

import { isLastItem } from "@/utils/array"

import newColors from "@/styles/newColors"

import { EnhanceData, EnhanceType } from "@/protocols/skynet"

import useCustomMemo from "@/hooks/useCustomMemo"
import useSubscriptionLimits from "@/hooks/useSubscriptionLimits"

import useStyles from "@/components/Skynet/TextEnhancementButton/styles"

type MenuConfigItem<Type extends EnhanceType>= {
	icon?: React.ReactNode
	title: React.ReactNode
	enhanceData: EnhanceData<Type>
	TextEnhancementDialogProps?: Pick<TextEnhancementDialogProps<EnhanceType>, "secondaryTitleText">
}

type MenuConfig<Type extends EnhanceType> = {
	title?: React.ReactNode
	items: MenuConfigItem<Type>[]
}

type EnhanceTypeToMenuConfig<Type extends EnhanceType> = Record<Type, MenuConfig<Type>>

type TextEnhancementButtonProps = {
	TextEnhancementDialogProps: Pick<TextEnhancementDialogProps<EnhanceType>, "anchorElement">
	IconButtonSvgIconProps?: SvgIconProps
	text: string
	onReplace: (text: string) => void
}

const DEFAULT_MENU_ICON_COLOR = newColors.purple[500]

const TextEnhancementButton: React.FC<TextEnhancementButtonProps> = (props) => {
	const {
		TextEnhancementDialogProps,
		IconButtonSvgIconProps,
		onReplace,
		text
	} = props

	const classes = useStyles()
	const aiTextEnhancementQuantityLimit = useSubscriptionLimits("quantity", "ai_text_enhancement")

	const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null)

	const ENHANCE_TYPE_TO_MENU_CONFIG: EnhanceTypeToMenuConfig<EnhanceType> = useCustomMemo(() => ({
		"generic-text-improvement": {
			items: [
				{
					icon: (
						<SvgIcon
							autoSize
							htmlColor={DEFAULT_MENU_ICON_COLOR}
							icon={GeneralTextImprovementIcon}
						/>
					),
					title: "Melhorar o texto",
					enhanceData: {
						text
					},
					TextEnhancementDialogProps: {
						secondaryTitleText: "Melhoria no texto"
					}
				}
			]
		},
		"tone-switch": {
			title: "Mudar o tom do texto",
			items: [
				{
					icon: (
						<SvgIcon
							autoSize
							htmlColor={DEFAULT_MENU_ICON_COLOR}
							icon={FriendlyToneIcon}
						/>
					),
					title: "Soar amigável",
					enhanceData: {
						text,
						tone: "friendly"
					},
					TextEnhancementDialogProps: {
						secondaryTitleText: (
							<Grid
								container
								alignContent="center"
							>
								<SvgIcon
									autoSize
									fontSize="small"
									htmlColor={DEFAULT_MENU_ICON_COLOR}
									icon={FriendlyToneIcon}
								/>

								<Divider orientation="vertical" size={0.5} />

								Tom amigável
							</Grid>
						)
					}
				},
				{
					icon: (
						<SvgIcon
							autoSize
							htmlColor={DEFAULT_MENU_ICON_COLOR}
							icon={ConfidentToneIcon}
						/>
					),
					title: "Soar confiante",
					enhanceData: {
						text,
						tone: "confident"
					},
					TextEnhancementDialogProps: {
						secondaryTitleText: (
							<Grid
								container
								alignContent="center"
							>
								<SvgIcon
									autoSize
									fontSize="small"
									htmlColor={DEFAULT_MENU_ICON_COLOR}
									icon={ConfidentToneIcon}
								/>

								<Divider orientation="vertical" size={0.5} />

								Tom confiante
							</Grid>
						)
					}
				},
				{
					icon: (
						<SvgIcon
							autoSize
							htmlColor={DEFAULT_MENU_ICON_COLOR}
							icon={DirectToneIcon}
						/>
					),
					title: "Soar direto",
					enhanceData: {
						text,
						tone: "direct"
					},
					TextEnhancementDialogProps: {
						secondaryTitleText: (
							<Grid
								container
								alignContent="center"
							>
								<SvgIcon
									autoSize
									fontSize="small"
									htmlColor={DEFAULT_MENU_ICON_COLOR}
									icon={DirectToneIcon}
								/>

								<Divider orientation="vertical" size={0.5} />

								Tom direto
							</Grid>
						)
					}
				},
				{
					icon: (
						<SvgIcon
							autoSize
							htmlColor={DEFAULT_MENU_ICON_COLOR}
							icon={EmpatheticToneIcon}
						/>
					),
					title: "Soar empático",
					enhanceData: {
						text,
						tone: "empathetic"
					},
					TextEnhancementDialogProps: {
						secondaryTitleText: (
							<Grid
								container
								alignContent="center"
							>
								<SvgIcon
									autoSize
									fontSize="small"
									htmlColor={DEFAULT_MENU_ICON_COLOR}
									icon={EmpatheticToneIcon}
								/>

								<Divider orientation="vertical" size={0.5} />

								Tom empático
							</Grid>
						)
					}
				},
				{
					icon: (
						<SvgIcon
							autoSize
							htmlColor={DEFAULT_MENU_ICON_COLOR}
							icon={ProfessionalToneIcon}
						/>
					),
					title: "Soar profissional",
					enhanceData: {
						text,
						tone: "professional"
					},
					TextEnhancementDialogProps: {
						secondaryTitleText: (
							<Grid
								container
								alignContent="center"
							>
								<SvgIcon
									autoSize
									fontSize="small"
									htmlColor={DEFAULT_MENU_ICON_COLOR}
									icon={ProfessionalToneIcon}
								/>

								<Divider orientation="vertical" size={0.5} />

								Tom profissional
							</Grid>
						)
					}
				}
			]
		}
	}), [text])

	const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElement(event.currentTarget)
	}

	const handleCloseMenu = () => {
		setAnchorElement(null)
	}

	const handleOpenTextEnhancementDialog = (
		enhanceType: EnhanceType,
		enhanceData: EnhanceData<EnhanceType>,
		textEnhancementDialogProps?: Partial<TextEnhancementDialogProps<EnhanceType>>
	) => {
		if (aiTextEnhancementQuantityLimit.mustBlock) {
			UpsellDialog.open({
				dialogCode: aiTextEnhancementQuantityLimit.blockCode
			})
		} else {
			TextEnhancementDialog.open({
				anchorElement: TextEnhancementDialogProps.anchorElement,
				enhanceType,
				enhanceData,
				onReplace,
				onTextEnhanced: () => aiTextEnhancementQuantityLimit.resetLimit(),
				...textEnhancementDialogProps
			})
		}

		handleCloseMenu()
	}

	const enhanceTypeAndMenuConfig = Object.entries(ENHANCE_TYPE_TO_MENU_CONFIG).map(([key, value]) => ({
		enhanceType: key as EnhanceType,
		menuConfig: value
	}))

	return (
		<>
			<Menu
				anchorEl={anchorElement}
				open={Boolean(anchorElement)}
				onClose={handleCloseMenu}
				autoFocus={false}
			>
				{enhanceTypeAndMenuConfig.map(({ enhanceType, menuConfig }, index) => (
					(
						<>
							{menuConfig.title && (
								<Grid
									key={`${enhanceType}-title`}
									className={classes.menuItemTitle}
									container
									alignContent="center"
								>
									<Typography
										variant="overline"
									>
										{menuConfig.title}
									</Typography>
								</Grid>
							)}

							{menuConfig.items.map((item, itemIndex) => (
								<MenuItem
									key={`${enhanceType}-menu-item-${itemIndex}`}
									onClick={() => handleOpenTextEnhancementDialog(enhanceType, item.enhanceData, item.TextEnhancementDialogProps)}
								>
									{item.icon && (
										<ListItemIcon
											className={classes.listItemIcon}
										>
											{item.icon}
										</ListItemIcon>
									)}

									<ListItemText
										primary={item.title}
										classes={{
											primary: classes.listItemText
										}}
									/>
								</MenuItem>
							))}

							{!isLastItem(index, enhanceTypeAndMenuConfig.length) && (
								<MuiDivider
									key={`${enhanceType}-divider`}
								/>
							)}
						</>
					)
				))}
			</Menu>

			<Tooltip
				title="Melhore seu texto, crie novas possibilidades e muito mais"
			>
				<IconButton
					onClick={handleOpenMenu}
					size="small"
				>
					<SvgIcon
						{...IconButtonSvgIconProps}
						htmlColor={newColors.purple[500]}
						icon={TextEnhancementIcon}
					/>
				</IconButton>
			</Tooltip>
		</>
	)
}

export default TextEnhancementButton
