import React, { ReactNode, useEffect, useState } from "react"
import {
	Grid,
	Avatar,
	Typography,
	Divider as MuiDivider,
	Box,
	IconButton,
	Switch,
	withStyles,
	Tooltip,
	InputLabel,
	TextField,
	Tabs,
	Tab
} from "@material-ui/core"

import { InfoOutlined as InfoIcon, Edit as EditIcon } from "@material-ui/icons"

import PhoneNumberRedirect from "@/components/PhoneNumberRedirect"
import { ActionDialog, Loading, Divider, Notification } from "@/components"

import ClientTags from "@/pages/Admin/ClientCatalog/ClientProfileInfo/Tags"
import ClientCustomFields from "@/pages/Admin/ClientCatalog/ClientProfileInfo/CustomFields"
import DatesAndIdentifier from "@/pages/Admin/ClientCatalog/ClientProfileInfo/DatesAndIdentifier"
import ClientNotesTable from "@/pages/Admin/ClientCatalog/ClientProfileInfo/Notes"
import HandleResponsibleAttendant from "@/pages/Admin/ClientCatalog/ClientProfileInfo/HandleResponsibleAttendant"

import ClientProfileInfoSkeleton from "@/skeletons/Admin/ClientProfileInfoSkeleton"

import { ClientProfileInfoData, TagDataProps } from "@/protocols/clientCatalog"
import { ChannelType } from "@/protocols/channel"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

import useBreakpoint from "@/hooks/useBreakpoint"
import useSocket from "@/hooks/useSocket"
import { useGlobalStateStore } from "@/store/GlobalState"
import useValidation, { ErrorType } from "@/hooks/useValidation"

import colors from "@/styles/colors"
import useCustomStyles from "@/styles/custom"
import useStyles from "@/pages/Admin/ClientCatalog/ClientProfileInfo/styles"
import ContactAction from "@/components/ContactAction"

export type OnDataChangeFn = (id: number, data: Partial<ClientProfileInfoData>) => void

type ClientProfileInfoProps = {
	children: ReactNode
	clientId: number
	onDataChange: OnDataChangeFn
	customerTags: TagDataProps[]
	onUpdateTags?: () => Promise<void>
}

type ManagementTabType = "customFields" | "notes"

type ClientChangeData = {
	email: string
	nickname: string
}

type ManagementTab = {
	title: string
	type: ManagementTabType
}

const MANAGEMENT_TABS: ManagementTab[] = [
	{
		title: "CAMPOS PERSONALIZADOS",
		type: "customFields"
	},
	{
		title: "NOTAS",
		type: "notes"
	}
]

const ClientProfileInfo: React.FC<ClientProfileInfoProps> = (props) => {
	const {
		children,
		customerTags,
		clientId,
		onDataChange,
		onUpdateTags
	} = props

	const socket = useSocket()
	const globalStateStore = useGlobalStateStore()

	const [openDialog, setOpenDialog] = useState(false)
	const [client, setClient] = useState<ClientProfileInfoData>({} as ClientProfileInfoData)
	const [loading, setLoading] = useState(true)
	const [isOptinEnabled, setIsOptinEnabled] = useState<boolean>(false)
	const [opeEditDialog, setOpenEditDialog] = useState(false)
	const [clientDataFields, setClientDataFields] = useState<ClientChangeData>({} as ClientChangeData)
	const [currentManagementTabType, setCurrentManagementTabType] = useState<ManagementTabType>(MANAGEMENT_TABS[0]?.type)
	const { validation, triggerValidation } = useValidation()

	const clientData = { clientId: client.id, contactId: client.contacts?.id, name: client.nickname || client.name }
	const handleStartChat = async (clientId: number, contactId: number) => {
		try {
			const currentChannel = globalStateStore.currentChannel

			const chat = await socket.startChat({
				channelType: currentChannel?.channelType as ChannelType,
				clientId,
				contactId,
				inboxChannelId: currentChannel?.id as number
			})
			setClient(lastState => ({
				...lastState,
				inboxChannelChat: {
					...lastState.inboxChannelChat,
					id: chat?.inboxChannelId
				}
			}))
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
	}

	const getClientProfileInfoData = async () => {
		setLoading(true)
		try {
			const { data } = await ApiService.get(`/clients/${clientId}/profile`)
			setIsOptinEnabled(data.optIn)
			setClient(data)

			if (!data.inboxChannelChat?.id && data.contacts?.id) {
				await handleStartChat(data.id, data.contacts?.id as number)
			}
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
		setLoading(false)
	}

	const handleUpdateClientEmail = async () => {
		try {
			const { data } = await ApiService.post(
				`/clients/${clientId}/email`,
				{
					email: clientDataFields.email
				}
			)

			const { updatedContact } = data

			const contacts = {
				email: updatedContact.data.email,
				registeredFrom: client.contacts?.registeredFrom,
				phone: client.contacts?.phone
			}

			setClient(lastState => {
				return {
					...lastState,
					contacts: contacts
				}
			})

			onDataChange(client.id, { contacts: { email: updatedContact.data.email } })

			Notification.success({ message: "Cliente atualizado." })
			setOpenEditDialog(false)
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
	}

	const handleUpdateClientOptIn = async () => {
		try {
			const payload = {
				nickname: client.nickname,
				accepts_automatic_messages: !isOptinEnabled
			}
			const { data } = await ApiService.put(`/clients/${client.id}`, payload)
			const { updatedClient } = data
			onDataChange(client.id, { optIn: updatedClient.accepts_automatic_messages })
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
	}

	const handleUpdateClientNickname = async () => {
		try {
			const { data } = await ApiService.put(
				`/clients/${clientId}`,
				{ nickname: clientDataFields.nickname }
			)

			const { updatedClient } = data

			setClient(lastState => {
				return {
					...lastState,
					nickname: updatedClient.nickname
				}
			})

			onDataChange(client.id, { nickname: updatedClient.nickname })

			Notification.success({ message: "Cliente atualizado." })
			setOpenEditDialog(false)
		} catch (err) {
			triggerValidation(err as ErrorType)
		}
	}

	const handleOptChange = () => {
		handleUpdateClientOptIn()
		setIsOptinEnabled(!isOptinEnabled)
	}

	const handleOpenDialog = async () => {
		setLoading(true)
		setOpenDialog(true)
	}

	const handleCloseDialog = () => {
		setOpenDialog(false)
	}

	const handleOpenEditClientNameAndEmailDialog = () => {
		setOpenEditDialog(true)
	}

	const handleCloseEditClientNameAndEmailDialog = () => {
		setOpenEditDialog(false)
	}

	const handleUpdateClientPersonalData = () => {
		if (clientDataFields.email !== client.contacts?.email) {
			handleUpdateClientEmail()
		}
		if (clientDataFields.nickname !== client.nickname) {
			handleUpdateClientNickname()
		}
	}

	const handleChangeFields = async (value: string | number, field: keyof ClientChangeData) => {
		setClientDataFields(lastState => ({
			...lastState,
			[field]: value
		}))
	}

	const handleChangeCurrentManagementTabType = (type: ManagementTabType): void => {
		setCurrentManagementTabType(type)
	}

	const customClasses = useCustomStyles()
	const classes = useStyles()
	const isSmall = useBreakpoint({ type: "down", breakpoint: "xs" })

	const CustomSwitch = withStyles({
		track: {
			backgroundColor: colors.unrelated.D32F2F
		},
		colorSecondary: {
			color: colors.unrelated.D32F2F

		}
	})(Switch)

	useEffect(() => {
		if (openDialog) {
			getClientProfileInfoData()
		}
	}, [openDialog])

	return (
		<>
			<div
				onClick={handleOpenDialog}
			>
				{children}
			</div>
			<ActionDialog
				hideCloseButton={true}
				onClose={handleCloseDialog}
				openDialog={openDialog}
				title={"PERFIL DO CONTATO"}
				fullWidth
				maxWidth="lg"
				dialogContentClassName={classes.resetDialogContentPadding}
			>
				<Loading loading={loading} customLoadingElement={<ClientProfileInfoSkeleton />}>
					{!loading && <Grid container direction="row" wrap={isSmall ? "wrap" : "nowrap"}>
						<Grid item style={{ width: isSmall ? "100%" : "40%" }}>
							<Grid item >
								<Grid container className={classes.clientIdentification}>
									<Grid item className={classes.avatarContainer}>
										<Avatar className={classes.avatar} src={client?.pictureUrl} />
									</Grid>
									<Grid item>
										{client.contacts?.phone && <PhoneNumberRedirect customizedClass={classes.phoneNumberSize} phoneNumber={client.contacts?.phone} />}
										<Typography className={classes.clientName}>
											~{client.name}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							<MuiDivider orientation="horizontal" className={classes.containerFillSize} />
							<Grid container justifyContent="center" style={{ padding: "8px 0px" }}>
								<ContactAction contactAction="single-contact" client={clientData} />
							</Grid>
							<MuiDivider orientation="horizontal" className={classes.containerFillSize} />
							<Grid container direction="column" alignItems="flex-start" style={{ padding: 32, paddingRight: 25 }}>
								<Grid item className={classes.containerFillSize}>
									<Grid container direction="column">
										<Grid item>
											<Grid container direction="row" alignItems="baseline" justifyContent="space-between">
												<Typography className={classes.clientNickname}>
													{client.nickname}
												</Typography>
												<IconButton onClick={handleOpenEditClientNameAndEmailDialog} style={{ padding: 7 }}>
													<EditIcon />
												</IconButton>
											</Grid>
										</Grid>
										<Grid item>
											<Typography className={classes.clientEmail}>
												{client.contacts?.email}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Divider orientation="horizontal" size={2} />
								<Grid item className={classes.containerFillSize}>
									<Grid container direction="row" justifyContent="space-between" >
										<Grid item>
											<Box className={classes.variableBox}>
												<Typography className={classes.clientOptIn}>Receber mensagem automática?</Typography>
												<Tooltip title={"Essa função é exclusivamente para uso em envio em massa, integração e bot (webhook e tag)."}>
													<InfoIcon className={classes.customInfoIcon} />
												</Tooltip>
											</Box>
										</Grid>
										<Grid item>
											<CustomSwitch
												checked={isOptinEnabled}
												size="small"
												onChange={handleOptChange}
												inputProps={{ "aria-label": "controlled" }}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>

							<MuiDivider orientation="horizontal" className={classes.containerFillSize} />

							<HandleResponsibleAttendant clientId={client.id} clientAttendantManager={client?.accountManagerUser} inboxChannelChatId={client.inboxChannelChat?.id} loading={loading} />

							<MuiDivider orientation="horizontal" className={classes.containerFillSize} />

							{customerTags && <ClientTags availableTags={customerTags} clientId={client.id} clientAssociatedTags={client.tags} onDataChange={onDataChange} onUpdateTags={onUpdateTags} />}

							<DatesAndIdentifier id={client.id} createdAt={client.createdAt} updatedAt={client.updatedAt} registeredFrom={client.contacts?.registeredFrom} />
						</Grid>

						<MuiDivider orientation="vertical" flexItem={true} />

						<Grid item style={{ paddingTop: isSmall ? 32 : 0 }} className={classes.clientPersonalDataView}>

							<Tabs
								color="primary"
								value={currentManagementTabType}
								className={classes.tabs}
								onChange={(_, value) => handleChangeCurrentManagementTabType(value)}
								classes={{
									indicator: classes.tabIndicator
								}}
							>
								{MANAGEMENT_TABS.map(managementTab => {
									return (
										<Tab
											key={managementTab.title}
											label={managementTab.title}
											value={managementTab.type}
											className={classes.tab}
										/>
									)
								})}
							</Tabs>

							{currentManagementTabType === "customFields" &&

								<ClientCustomFields onDataChange={onDataChange} clientId={client.id} customFieldGroups={client.customFieldGroups} />
							}

							{currentManagementTabType === "notes" &&

								<ClientNotesTable inboxChannelChat={client.inboxChannelChat} clientId={client.id} isExpandedNotes={false} loading={false} />
							}
						</Grid>

					</Grid>}

				</Loading>
			</ActionDialog>

			<ActionDialog
				onClose={handleCloseEditClientNameAndEmailDialog}
				openDialog={opeEditDialog}
				title={"Alterar dados do cliente"}
				fullWidth
				saveText={"Salvar"}
				cancelText="Cancelar"
				onSave={handleUpdateClientPersonalData}
			>
				<Grid container>
					<Grid xs={12}>
						<Grid
							item
							xs
						>
							<InputLabel className={customClasses.inputLabel}>Nome:</InputLabel>
							<Divider size={2} orientation="horizontal" />

							<TextField
								name="client_name"
								variant="outlined"
								placeholder={"Digite o novo nome"}
								fullWidth
								defaultValue={client.nickname}
								value={clientDataFields.nickname}
								helperText={validation.nickname}
								error={!!validation.nickname}
								onChange={({ target }) => handleChangeFields(target.value, "nickname")}
							/>
						</Grid>
						<Divider size={3} orientation="horizontal" />
						<Grid
							item
							xs
						>
							<InputLabel className={customClasses.inputLabel}>Email:</InputLabel>
							<Divider size={2} orientation="horizontal" />

							<TextField
								name="email_field"
								variant="outlined"
								placeholder={"Digite o novo email"}
								fullWidth
								defaultValue={client.contacts && client.contacts.email}
								value={clientDataFields.email}
								onChange={({ target }) => handleChangeFields(target.value, "email")}
							/>
						</Grid>
					</Grid>
				</Grid>
			</ActionDialog>
		</>
	)
}

export default ClientProfileInfo
