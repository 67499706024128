import React, { useEffect, useRef, useState } from "react"
import clsx from "clsx"
import _ from "lodash"
import {
	Grid,
	List,
	ListItem,
	Typography,
	InputAdornment,
	Input,
	Tooltip
} from "@material-ui/core"
import useAutocomplete, { AutocompleteCloseReason } from "@material-ui/lab/useAutocomplete"
import {
	Search as SearchIcon
} from "@material-ui/icons"

import {
	Divider,
	AccessibleDrawer,
	Loading,
	Portal
} from "@/components"
import MessageItem from "@/components/ChatMessageBuilder/MessageItem"

import {
	WABAChannelMessageTemplate
} from "@/@integrations/WABA/protocols/wabaChannelMessageTemplate"

import useDidMount from "@/hooks/useDidMount"
import useGlobalItemSelection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useGlobalItemSelection"
import useConstantId from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useConstantId"
import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"

import { runAfterReactRender } from "@/utils/node"

import HeaderDrawer from "@/pages/Attendance/Chat/HeaderDrawer"

import WABAMessageTemplateSelectorSkeleton from "@/@integrations/WABA/skeletons/WABAMessageTemplateSelectorSkeleton"

import { BLOCK_BODY_SECTION_EDITOR_ID } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/flowResources"

import { getTemplateStatusChip } from "@/@integrations/WABA/utils/template"

import useStyles from "@/@integrations/WABA/components/Chat/WABAMessageTemplateSelector/styles"
import useChatStyles from "@/pages/Attendance/Chat/styles"
import useCustomStyles from "@/styles/custom"

export type WABAMessageTemplateFlowBlockSelectorProps = {
	onSelect: (wabaChannelMessageTemplate: WABAChannelMessageTemplate) => Promise<void>
	onClose?: () => void
	inboxChannelId: number
	container: Element
	onOpen: () => void
}

const WABAMessageTemplateFlowBlockSelector: React.FC<WABAMessageTemplateFlowBlockSelectorProps> = (props) => {
	const {
		onSelect,
		onClose,
		container,
		onOpen
	} = props

	const searchInputRef = useRef<HTMLInputElement>(null)

	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()

	const [focusedWABAChannelMessageTemplateId, setFocusedWABAChannelMessageTemplateId] = useState<number | null>(null)
	const [loading, setLoading] = useState(true)
	const [search, setSearch] = useState(null)

	const wabaChannelMessageTemplates = chatBotFlowConstructorStore.constructionResources.wabaChannelMessageTemplates
	const orderedWabaChannelMessageTemplates = _.orderBy(wabaChannelMessageTemplates, [({ status }) => status === "approved", "name"], ["desc", "asc"])
	const focusedWABAChannelMessageTemplate = wabaChannelMessageTemplates.find(({ id }) => id === focusedWABAChannelMessageTemplateId)

	const flowBlockSectionEditorSelection = useGlobalItemSelection("flow-editor-right-drawer")
	const blockSectionId = useConstantId()
	const isBeingEdited = flowBlockSectionEditorSelection.isSelected(blockSectionId)

	const classes = useStyles()
	const customClasses = useCustomStyles()
	const chatClasses = useChatStyles()

	const handleClose = () => {
		setSearch(null)

		flowBlockSectionEditorSelection.clearSelection()

		onClose?.()
	}

	const handleOpen = async () => {
		setLoading(true)

		onOpen()

		flowBlockSectionEditorSelection.clearSelection()
		flowBlockSectionEditorSelection.toggleSelected(blockSectionId)

		setLoading(false)
	}

	const handleSelectTemplate = async (wabaChannelMessageTemplate: WABAChannelMessageTemplate) => {
		await onSelect(wabaChannelMessageTemplate)
		handleClose()
	}

	const isValidTemplate = (wabaChannelMessageTemplate?: WABAChannelMessageTemplate) => {
		return wabaChannelMessageTemplate?.status === "approved"
	}

	const focusOnAutoCompleteInput = () => {
		if (isBeingEdited) {
			runAfterReactRender(() => {
				searchInputRef.current?.focus()
			})
		}
	}

	const onAutoCompleteClose = (reason: AutocompleteCloseReason) => {
		const closedBySelectingItem = reason === "select-option"
		const isTemplateSelectable = isValidTemplate(focusedWABAChannelMessageTemplate)

		const isAbleToSelectTemplate = closedBySelectingItem && isTemplateSelectable

		if (isAbleToSelectTemplate) {
			handleSelectTemplate(focusedWABAChannelMessageTemplate as WABAChannelMessageTemplate)
		}
	}

	const onAutoCompleteHighlightChange = (wabaChannelMessageTemplate: WABAChannelMessageTemplate | null) => {
		if (wabaChannelMessageTemplate) {
			setFocusedWABAChannelMessageTemplateId(wabaChannelMessageTemplate.id)
		}
	}

	const setupAutoCompleteEventListeners = () => {
		window.addEventListener("keydown", ({ key }) => {
			if (key === "Escape") {
				handleClose()
			}
		}, { capture: true })
	}

	const {
		getRootProps,
		getInputLabelProps,
		getInputProps,
		getListboxProps,
		getOptionProps,
		groupedOptions
	} = useAutocomplete<WABAChannelMessageTemplate>({
		id: "waba-channel-message-template-flow-block-autocomplete",
		options: orderedWabaChannelMessageTemplates,
		open: true,
		clearOnEscape: true,
		selectOnFocus: true,
		clearOnBlur: true,
		autoHighlight: true,
		value: focusedWABAChannelMessageTemplate,
		getOptionLabel: (option) => option.name,
		onHighlightChange: (_, option) => onAutoCompleteHighlightChange(option),
		onClose: (_, reason) => onAutoCompleteClose(reason),
		blurOnSelect: false
	})

	useEffect(() => {
		focusOnAutoCompleteInput()
	}, [isBeingEdited])

	useDidMount(() => {
		setupAutoCompleteEventListeners()
	})

	return (
		<>
			<Portal
				container={container}
			>
				<AccessibleDrawer
					id={BLOCK_BODY_SECTION_EDITOR_ID}
					anchor="right"
					open={isBeingEdited}
					variant="persistent"
					onClose={handleClose}
					onMobileBackButtonPress={handleClose}
					withoutTriggerHistoryBackEvent
					classes={{
						paper: chatClasses.drawerPaper
					}}
				>
					<Grid container>
						<Grid item xs={12}>
							<HeaderDrawer
								title="Templates da WABA"
								onClose={handleClose}
							/>
						</Grid>

						<Grid item xs={12}>
							<Grid container {...getRootProps()}>
								<Grid item xs={12} className={classes.searchInputContainer}>
									<Input
										{...getInputProps()}
										inputRef={searchInputRef}
										value={search}
										startAdornment={
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										}
										fullWidth
										className={classes.searchInput}
									/>
								</Grid>

								<Loading
									loading={loading}
									customLoadingElement={<WABAMessageTemplateSelectorSkeleton />}
								>
									<Grid item xs={12}>
										{groupedOptions.length ? (
											<List
												{...getListboxProps()}
												className={clsx({
													[classes.listContainer]: true,
													[customClasses.scrollBar]: true
												})}
											>
												{groupedOptions.map((wabaChannelMessageTemplate, index) => {
													const isTemplateDisabled = !isValidTemplate(wabaChannelMessageTemplate)
													const isTemplateFocused = focusedWABAChannelMessageTemplateId === wabaChannelMessageTemplate.id

													return (
														<ListItem
															{...getOptionProps({ option: wabaChannelMessageTemplate, index })}
															key={wabaChannelMessageTemplate.id}
															className={clsx({
																[classes.listItem]: true,
																[classes.listItemClickable]: !isTemplateDisabled
															})}
															disabled={isTemplateDisabled}
														>
															<Grid
																container
															>
																<Grid
																	item
																	xs={12}
																>
																	<Grid
																		container
																		alignItems="center"
																	>
																		{getTemplateStatusChip(wabaChannelMessageTemplate)}

																		<Divider size={1} orientation="vertical" />

																		<Typography
																			{...getInputLabelProps()}
																			variant="caption"
																			className={classes.shortcutNameText}
																		>
																			{wabaChannelMessageTemplate.name}
																		</Typography>
																	</Grid>
																</Grid>

																<Divider size={1} orientation="horizontal" />

																<Grid
																	item
																	xs={12}
																>
																	<Tooltip
																		title={isTemplateDisabled ? "É possível enviar apenas templates que foram aprovados pela Meta." : ""}
																		placement="right"
																	>
																		<Grid
																			container
																		>
																			<MessageItem
																				copySpecial={false}
																				type={wabaChannelMessageTemplate.message.type}
																				content={wabaChannelMessageTemplate.message.content}
																				mediaName={wabaChannelMessageTemplate.message.mediaName}
																				extraData={wabaChannelMessageTemplate.message.extraData}
																				className={clsx({
																					[classes.unfocusedMessageItem]: !isTemplateFocused || isTemplateDisabled
																				})}
																				style={{
																					position: "relative"
																				}}
																			/>
																		</Grid>
																	</Tooltip>
																</Grid>
															</Grid>
														</ListItem>
													)
												})}
											</List>
										) : (
											<>
												<Divider size={3} orientation="horizontal" />

												<Typography align="center" variant="h2">
													Nenhum template encontrado
												</Typography>
											</>
										)}
									</Grid>
								</Loading>
							</Grid>
						</Grid>
					</Grid>
				</AccessibleDrawer>
			</Portal>

			<Grid
				onClick={() => {
					if (!isBeingEdited) {
						handleOpen()
					} else {
						handleClose()
					}
				}}
			>
				{props.children}
			</Grid>
		</>
	)
}

export default WABAMessageTemplateFlowBlockSelector
