import React from "react"
import {
	Grid,
	Tooltip,
	Typography
} from "@material-ui/core"

import { ChatBotFlowBlockContent } from "@/protocols/chatBot"
import {
	WABAChannelMessageTemplate
} from "@/@integrations/WABA/protocols/wabaChannelMessageTemplate"

import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import WABAMessageTemplateFlowBlockSelector from "@/@integrations/WABA/components/Flow/WABAMessageTemplateFlowBlockSelector"
import BlockBodySectionContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer"
import { Divider } from "@/components"
import MessageItem from "@/components/ChatMessageBuilder/MessageItem"
import ContrastChip from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ContrastChip"

import { FLOW_EDITOR_CONTAINER_ID } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/flowResources"

import { getTemplateStatusChip } from "@/@integrations/WABA/utils/template"

import { useGlobalStateStore } from "@/store/GlobalState"
import useContentEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useContentEditor"

import colors from "@/styles/colors"
import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/SendWABATemplateMessageAndWaitBlock/WABATemplateMessageSectionEditor/styles"

type WABATemplateMessageSectionEditorProps = {
	onSave: (wabaChannelMessageTemplateId: number) => void
	chatBotFlowBlockContent: ChatBotFlowBlockContent
	wabaChannelMessageTemplate?: WABAChannelMessageTemplate
}

const WABATemplateMessageSectionEditor: React.FC<WABATemplateMessageSectionEditorProps> = (props) => {
	const {
		onSave,
		chatBotFlowBlockContent,
		wabaChannelMessageTemplate
	} = props

	const flowEditorContainer = document.getElementById(FLOW_EDITOR_CONTAINER_ID) as Element
	const globalStateStore = useGlobalStateStore()

	const contentEditor = useContentEditor({ chatBotFlowBlockContent })

	const classes = useStyles()

	const handleSave = async (wabaChannelMessageTemplate: WABAChannelMessageTemplate) => {
		contentEditor.changeContent({
			wabaChannelMessageTemplate: {
				id: wabaChannelMessageTemplate.id
			}
		})

		onSave(wabaChannelMessageTemplate.id)
	}

	return (
		<BlockBodySection
			fullWidth
		>
			<WABAMessageTemplateFlowBlockSelector
				inboxChannelId={Number(globalStateStore.wabaChannel?.id)}
				onSelect={async (wabaChannelMessageTemplate: WABAChannelMessageTemplate) => await handleSave(wabaChannelMessageTemplate)}
				container={flowEditorContainer}
				onOpen={() => contentEditor.reset()}
			>
				<Grid
					container
					style={{
						cursor: "pointer"
					}}
				>
					{wabaChannelMessageTemplate ? (
						<>
							<BlockBodySectionContainer
								text={(
									<Grid
										container
									>
										<Grid
											item
											xs={12}
										>
											<Typography
												variant="caption"
												className={classes.templateName}
											>
												Nome: {" "}
												<Tooltip title={wabaChannelMessageTemplate.name}>
													<Typography
														variant="caption"
														className={classes.shortcutNameText}
													>
														{wabaChannelMessageTemplate.name}
													</Typography>
												</Tooltip>
											</Typography>
										</Grid>

										<Grid
											item
											xs={12}
										>
											<Typography
												variant="caption"
											>
												Status: {" "}
												{getTemplateStatusChip(wabaChannelMessageTemplate)}
											</Typography>
										</Grid>
									</Grid>
								)}
							/>

							<Divider orientation="horizontal" size={1} />

							<Grid
								container
							>
								<Grid
									container
									direction="row"
									alignItems="flex-start"
									className={classes.container}
									style={{
										backgroundColor: colors.palette.whatsappSentMessage
									}}
								>
									<MessageItem
										copySpecial={false}
										type={wabaChannelMessageTemplate.message?.type}
										content={wabaChannelMessageTemplate.message?.content}
										mediaName={wabaChannelMessageTemplate.message?.mediaName}
										extraData={wabaChannelMessageTemplate.message?.extraData}
										style={{
											position: "relative",
											padding: 0
										}}
									/>
								</Grid>
							</Grid>
						</>
					) : (
						<Grid
							container
						>
							<Grid
								container
								direction="row"
								alignItems="flex-start"
								className={classes.container}
								style={{
									backgroundColor: colors.palette.whatsappSentMessage
								}}
							>
								<ContrastChip
									title="Nenhum template selecionado"
									backgroundColor={colors.unrelated.E3E6E8}
									style={{
										cursor: "pointer"
									}}
								/>
							</Grid>
						</Grid>
					)}
				</Grid>
			</WABAMessageTemplateFlowBlockSelector>
		</BlockBodySection>
	)
}

export default WABATemplateMessageSectionEditor
