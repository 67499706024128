/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { ReactNodeArray } from "react"
import { find as findLinks } from "linkifyjs"
import { verifyIfIsOldSyntax, oldSyntaxTranslator } from "./oldWhatsappTranslator"
import { generateUUID } from "./id"
import colors from "@/styles/colors"

type FirstLink = { value: string, href: string }
type WhatsAppMarkdowns = "bold" | "italic" | "strikethrough" | "monospace" | "link" | "text"
type TypeByFirstAndLastChar = Record<string, { firstChar: string, lastChar: string }>

export default function translateWhatsAppSyntaxToHTML (text: string, translateVariables = true): ReactNodeArray {
	const { textWithoutVariables, replacedVariables } = replaceVariablesWithIds(text)
	return _translateWhatsAppSyntaxToHTML(textWithoutVariables, replacedVariables, translateVariables)
}

function _translateWhatsAppSyntaxToHTML (text: string, replacedVariables: Record<string, string>, translateVariables: boolean): ReactNodeArray {
	let textInWhatsAppSyntax = text
	if (verifyIfIsOldSyntax(text)) {
		textInWhatsAppSyntax = oldSyntaxTranslator(textInWhatsAppSyntax)
	}

	const splitText = splitWhatsAppText(textInWhatsAppSyntax)
	const components = splitText.map(({ type, content, href }) => {
		if (type === "link") return componentBuilders.link(content, href)
		if (type === "text") return componentBuilders.text(content, replacedVariables, translateVariables)

		return componentBuilders[type](_translateWhatsAppSyntaxToHTML(content, replacedVariables, translateVariables))
	})

	return components
}

/* eslint-disable react/display-name */
// eslint-disable-next-line
export const componentBuilders: Record<WhatsAppMarkdowns, (...args: any) => React.ReactNode | React.ReactNodeArray | string> = {
	text: (content: string, replacedVariables: Record<string, string>, translateVariables: boolean) => {
		const withVariables = Object.entries(replacedVariables).reduce((text, [key, value]) => text.replace(key, value), content)
		if (translateVariables) {
			return replaceVariablesWithHTML(withVariables)
		}

		return withVariables
	},
	bold: (content: string) => <strong>{content}</strong>,
	italic: (content: string) => <em>{content}</em>,
	strikethrough: (content: string) => <del>{content}</del>,
	monospace: (content: string) => <code>{content}</code>,
	link: (content: string, href: string) => <a href={href} target="_blank" rel="noopener noreferrer">{content}</a>
}
/* eslint-enable react/display-name */

function splitWhatsAppText (text: string) {
	const markdownsRegex = `${boldRegex}|${italicRegex}|${strikethroughRegex}|${monospaceRegex}`

	const textArray: Array<{ type: WhatsAppMarkdowns, content: string, href?: string }> = []
	let leftText = text
	while (leftText?.length > 0) {
		const firstLink = findFirstLink(leftText)
		const regexUnion = firstLink ? RegExp(`${escapeRegex(firstLink.value)}|${markdownsRegex}`) : RegExp(markdownsRegex)

		const exec = regexUnion.exec(leftText)
		if (exec) {
			const foundText = exec[0]
			const foundStartIndex = exec.index
			const contentBeforeFound = leftText.slice(0, foundStartIndex)
			if (contentBeforeFound.length > 0) {
				textArray.push({
					type: "text",
					content: contentBeforeFound
				})
			}

			let type: WhatsAppMarkdowns = "link"

			const foundTextFirstChar = foundText[0]
			const foundTextLastChar = foundText[foundText.length - 1]

			const isMarkdown = firstChars.includes(foundTextFirstChar) && lastChars.includes(foundTextLastChar)
			if (isMarkdown) {
				type = getTypeByFirstAndLastChar(foundTextFirstChar, foundTextLastChar)
			}

			const possibleFormattableMarkdowns = ["bold", "italic", "strikethrough"]
			let foundTextWithoutMarkdowns = foundText
			if (possibleFormattableMarkdowns.includes(type)) {
				const isValidMarkdownContent = foundText.length > 2 && /\S/.test(foundText.slice(1, foundText.length - 1))

				if (isValidMarkdownContent) {
					foundTextWithoutMarkdowns = foundText.slice(1, foundText.length - 1)
				} else {
					type = "text"
					foundTextWithoutMarkdowns = foundText
				}
			} else if (type === "monospace") {
				if (foundText.length > 6 && /\S/.test(foundText.slice(3, foundText.length - 3))) {
					foundTextWithoutMarkdowns = foundText.slice(3, foundText.length - 3)
				} else {
					type = "text"
					foundTextWithoutMarkdowns = foundText
				}
			}

			textArray.push({
				type,
				content: foundTextWithoutMarkdowns,
				href: firstLink?.href
			})

			leftText = leftText.slice(foundStartIndex + foundText.length)
		} else {
			textArray.push({ type: "text", content: leftText })
			leftText = ""
		}
	}

	return textArray
}

const findFirstLink = (content: string): FirstLink | null => findLinks(content)[0] || null
const escapeRegex = (string: string) => string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") // $& means the whole matched string
const typeByFirstAndLastChar: TypeByFirstAndLastChar = {
	bold: { firstChar: "*", lastChar: "*" },
	italic: { firstChar: "_", lastChar: "_" },
	strikethrough: { firstChar: "~", lastChar: "~" },
	monospace: { firstChar: "`", lastChar: "`" }
}
const firstChars = Object.values(typeByFirstAndLastChar).map(({ firstChar }) => firstChar)
const lastChars = Object.values(typeByFirstAndLastChar).map(({ lastChar }) => lastChar)
const buildFromCharToCharRegex = (fromChar: string, toChar: string) => `\\${fromChar}(?=\\S)(.*?)(?<=\\S)\\${toChar}`
const boldRegex = buildFromCharToCharRegex("*", "*")
const italicRegex = buildFromCharToCharRegex("_", "_")
const strikethroughRegex = buildFromCharToCharRegex("~", "~")
const monospaceRegex = buildFromCharToCharRegex("```", "```")

function getTypeByFirstAndLastChar (firstChar: string, lastChar: string): WhatsAppMarkdowns {
	const type = Object.keys(typeByFirstAndLastChar).find(type => {
		const chars = typeByFirstAndLastChar[type]
		if (!chars) {
			return false
		}

		const isValidFirstChar = chars.firstChar === firstChar
		const isValidLastChar = chars.lastChar === lastChar

		const isFoundType = isValidFirstChar && isValidLastChar
		return isFoundType
	})

	return type as WhatsAppMarkdowns
}

function replaceVariablesWithIds (text: string): {
	textWithoutVariables: string
	replacedVariables: Record<string, string>
} {
	let newText = text
	const regex = RegExp(buildFromCharToCharRegex("{{", "}}"))
	const replacedVariables: Record<string, string> = {}

	let exec = regex.exec(newText)
	while (exec) {
		const id = generateUUID()
		const variable = exec[0]
		newText = newText.replace(variable, id)
		replacedVariables[id] = variable
		exec = regex.exec(newText)
	}

	return {
		textWithoutVariables: newText,
		replacedVariables
	}
}

function replaceVariablesWithHTML (text: string): ReactNodeArray | string {
	const regex = RegExp(buildFromCharToCharRegex("{{", "}}"))

	let rightText = text
	let exec = regex.exec(rightText)
	if (!exec) {
		return text
	}
	const components: ReactNodeArray = []
	while (exec) {
		const leftText = rightText.slice(0, exec.index)
		components.push(leftText)
		components.push(
			<b style={{
				backgroundColor: "rgba(126, 13, 214, 0.7)",
				color: colors.grayScale[11],
				borderRadius: "5px",
				padding: "1px 2px",
				margin: "0px 1px"
			}}>
				{`{{ ${exec[1].trim()} }}`}
			</b>
		)

		rightText = rightText.slice(exec.index + exec[0].length)
		exec = regex.exec(rightText)
	}

	components.push(rightText)
	return components
}
