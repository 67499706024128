import React, { useEffect, useState } from "react"

import { AdminRoutes } from "@/routes/private"
import { Container, Grid } from "@material-ui/core"

import useStyles from "@/pages/Admin/styles"
import { Divider, Navbar } from "@/components"
import Menu from "@/pages/Admin/Menu"
import { useLocation, matchPath } from "react-router"
import useDidMount from "@/hooks/useDidMount"
import { useGlobalStateStore } from "@/store/GlobalState"
import { runWootric } from "@/utils/wootric"

import AITextEnhancementAdminUpsellDialog from "@/components/UpsellDialog/AITextEnhancementAdminUpsellDialog"

const ROUTES_WITHOUT_CONTAINER_WIDTH_LIMITATION: string[] = [
	"/admin/bot",
	"/admin/bots/:chatBotFlowId",
	"/admin/flow/:chatBotFlowId"
]

const Admin: React.FC = () => {
	const [openDrawer, setOpenDrawer] = useState(false)

	const globalStateStore = useGlobalStateStore()

	const isTrial = globalStateStore?.instance?.subscriptionData?.isTrial

	const classes = useStyles({ openDrawer, isTrial })
	const location = useLocation()

	const isCurrentPath = (path: string) => {
		const matchedPath = matchPath(location.pathname, { path, exact: true, strict: true })

		return 	Boolean(matchedPath)
	}

	const handleDrawerOpen = () => {
		setOpenDrawer(true)
	}

	const handleDrawerClose = () => {
		setOpenDrawer(false)
	}

	useEffect(() => {
		handleDrawerClose()
	}, [location.pathname])

	useDidMount(() => {
		window.name = "inbox_admin_page"

		const userRoleCode = globalStateStore?.instance?.user_in_instance_role?.code

		if (userRoleCode) {
			runWootric(globalStateStore.user?.email, new Date(globalStateStore.user?.created_at), userRoleCode)
		}
	})
	return (
		<Grid
			container
			direction="column"
			wrap="nowrap"
		>
			<Navbar
				title="PAINEL DO GESTOR"
				onOpen={handleDrawerOpen}
				hiddenComponents={{
					indicationMemberButton: true
				}}
			/>

			<Grid
				container
				className={classes.adminContainer}
			>
				<Menu
					openDrawer={openDrawer}
					onClose={handleDrawerClose}
				/>

				<Grid
					container
					justify="center"
					className={classes.routesContainer}
				>
					<Container
						className={classes.routesContent}
						style={{
							...(ROUTES_WITHOUT_CONTAINER_WIDTH_LIMITATION.some(path => isCurrentPath(path)) && ({
								maxWidth: "none"
							}))
						}}
					>
						<Divider size={4} orientation="horizontal" />

						<AdminRoutes />

						<Divider size={4} orientation="horizontal" />
					</Container>
				</Grid>
			</Grid>

			<AITextEnhancementAdminUpsellDialog />
		</Grid>
	)
}

export default Admin
