import { MappedMessageErrorCode } from "@/@integrations/WABA/protocols/error"
import { MessageErrorDetails } from "@/protocols/messages"

export const getWABAMessageErrorDetails = (error: string): Partial<MessageErrorDetails> | undefined => {
	const errorCode = error as MappedMessageErrorCode

	const errorCodeToMessageErrorDetails: Record<MappedMessageErrorCode, Partial<MessageErrorDetails> | undefined> = {
		ChatWithoutOpenedAttendanceWindow: {
			title: "Sem permissão de comunicação da WABA",
			description: "Só é possível enviar mensagens em formato livre dentro de 24h desde a última mensagem enviada pelo contato."
		},
		ContactDoesNotExist: {
			title: "Contato não existe",
			description: "Só é possível enviar mensagens para números de telefone que estejam registrados no Whatsapp."
		},
		TemplateDoesNotExist: {
			title: "Template não existe na Meta",
			description: "Só é possível enviar templates que foram criados com sucesso na Meta."
		},
		SendableTemplateMessageDoesNotExist: {
			title: "Template não existe na Letalk",
			description: "Só é possível enviar templates que foram sincronizados com a Letalk."
		},
		SendableSessionMessageNotMapped: {
			title: "Tipo de mensagem não implementado",
			description: "Só é possível enviar mensagens em formato livre que foram implementadas na Letalk."
		},
		InvalidMessagePayload: {
			title: "Mensagem inválida",
			description: "Só é possível enviar mensagens em formato livre que estejam dentro do padrão estrutural da Meta."
		},
		GupshupAccountWithoutCredits: {
			title: "Conta da Gupshup sem creditos",
			description: "Só é possível enviar mensagens enquanto houver creditos na conta da Gupshup."
		},
		Unknown: {}
	}

	return errorCodeToMessageErrorDetails[errorCode]
}
